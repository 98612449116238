@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap";
* {
  font-family: Lato;
}

:root {
  --light-green: #70bb70;
  --light-gray: #e8e8e8;
  --white: #fff;
  --brown: #a59750;
  --dark-gray: #353535;
  --yellow: #f2cd32;
  --dark-green: #157116;
  --black: #000;
  --unnamed-font-family-lato: Lato;
  --unnamed-font-family-fontawesome: FontAwesome;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-text-align: justify;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-32: 32px;
  --unnamed-font-size-40: 40px;
  --unnamed-font-size-50: 50px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-19: 25px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-39: 39px;
  --unnamed-line-spacing-48: 48px;
  --unnamed-line-spacing-60-000003814697266: 60px;
}

.textos {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: var(--unnamed-text-align);
  color: var(--dark-gray);
}

.fontawesome {
  font-family: var(--unnamed-font-family-fontawesome);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-50);
  line-height: var(--unnamed-line-spacing-60-000003814697266);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--black);
}

.h4 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
}

.h3 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--amarillo-cc-secundario);
}

.h2 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-32);
  line-height: var(--unnamed-line-spacing-39);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-green);
}

.h1 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-40);
  line-height: var(--unnamed-line-spacing-48);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--negro-secundario);
}

body {
  background-color: #fff;
}

.navbar-text a {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 16px / var(--unnamed-line-spacing-26) var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #fff;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
}

.sectionTitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40) / var(--unnamed-line-spacing-48) var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #fff;
  text-align: left;
  letter-spacing: 0;
  font: bold 40px / 48px Lato;
}

.followUpTitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40) / var(--unnamed-line-spacing-48) var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #f2cd32;
  text-align: left;
  letter-spacing: 0;
  font: 40px / 48px Lato;
}

.sectionTitleMark {
  color: var(--yellow);
}

.iconColors {
  opacity: .25;
}

.newsletterText {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  letter-spacing: 0;
  color: #fff;
  opacity: 1;
  font: bold 33px Lato;
}

.bannerText {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  letter-spacing: 0;
  color: var(--dark-green);
  opacity: 1;
  font: bold 33px Lato;
}

.white {
  color: var(--white);
}

.dgray {
  color: var(--dark-gray);
}

.dgreen {
  color: var(--dark-green);
}

.d-greenbg {
  background-color: var(--dark-green);
}

.nft-video {
  border-radius: 5px;
}

.imgSize {
  width: 40px;
  height: 40px;
}

.web3modal-modal-lightbox {
  z-index: 5 !important;
}

@media (width <= 1300px) {
  .newsletterText {
    font: bold 26px Lato;
  }
}

@media (width <= 1100px) {
  .newsletterText {
    font: bold 19px Lato;
  }
}

@media (width <= 700px) {
  .sectionTitleMark {
    display: none !important;
  }
}

@media (width <= 400px) {
  .newsletterText {
    text-align: center;
  }

  .container-input-mail {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

.dropdownbg[data-v-d15f16] {
  background-color: #d2c169;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.walletAddress[data-v-d15f16] {
  text-align: center;
  color: #fff;
  background-color: #353535;
  font-size: 10px;
}

.walletAddressInverted[data-v-d15f16] {
  text-align: center;
  color: #fff;
  background-color: #353535;
  font-size: 16px;
}

.connectWallet[data-v-d15f16] {
  background-color: #d2c169;
  border: 0;
  border-radius: 5px;
  padding: 8px 12px;
}

.connectWallet[data-v-d15f16]:hover {
  background-color: #a59750;
}

.connectWalletActive[data-v-d15f16] {
  background-color: #d2c169;
  border: 0;
  border-radius: 50px;
  padding: 8px 12px;
}

.connectWalletActive[data-v-d15f16]:hover {
  background-color: #a59750;
}

.walletDropdownOption[data-v-d15f16] {
  color: #fff;
  background-color: #d2c169;
}

.logout[data-v-d15f16] {
  color: #353535;
  background-color: #d2c169;
}

.disconnectButton[data-v-d15f16] {
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.rotate-arrow[data-v-d15f16] {
  transform: rotate(45deg);
}

@media (width <= 1600px) {
  .dropdown-menu[data-v-d15f16] {
    left: auto !important;
    right: 0 !important;
  }
}

.navbar-container[data-v-594aca] {
  background-color: #157116;
  padding: 0 9vw;
}

.inline[data-v-594aca] {
  flex-direction: row;
  display: flex;
}

.main-content[data-v-594aca] {
  background-color: coral;
  min-height: 8.3vh;
}

.navbar-text a[data-v-594aca]:hover, .navbar-text a[data-v-594aca]:focus {
  color: #fff;
}

.navbar-nav a.active[data-v-594aca], .navbar-nav a[data-v-594aca]:hover {
  color: #f2cd32;
}

.navbar-toggler-icon[data-v-594aca] {
  width: 20px;
  height: 20px;
}

@media (width <= 1600px) {
  .navbar-container[data-v-594aca] {
    background-color: #157116;
    padding: 0 5vw;
  }
}

@media (width <= 400px) {
  .logo-navbar[data-v-594aca] {
    width: 220px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.main-content[data-v-24eafc] {
  background: #f6f6f6 no-repeat padding-box padding-box;
  min-height: 27vh;
}

.green-container[data-v-24eafc] {
  background: #70bb70;
  border-radius: 5px;
  width: 100%;
  height: 18vh;
}

.subscribe[data-v-24eafc] {
  background-color: #d2c169;
  border: 0;
  font-family: Lato;
  font-size: 17px;
}

.subscribe[data-v-24eafc]:hover {
  background-color: #a59750;
}

.banner-container[data-v-24eafc] {
  height: 100%;
  margin: 0 2vw;
}

.enter-email[data-v-24eafc] {
  border: 0;
  border-radius: 5px;
  width: 40vw;
  margin-right: 1vw;
  padding-left: 25px;
  font-family: Lato;
}

[data-v-24eafc]::placeholder {
  color: #ebebeb;
  opacity: 1;
}

@media (width <= 800px) {
  .br-newsletter[data-v-24eafc] {
    display: none;
  }

  .banner-container[data-v-24eafc] {
    flex-direction: column;
    justify-content: center !important;
  }

  .newsletterText[data-v-24eafc] {
    margin-bottom: 20px;
  }
}

@media (width <= 400px) {
  .green-container[data-v-24eafc] {
    height: 200px;
  }

  .enter-email[data-v-24eafc] {
    width: 90%;
    height: 50px;
  }

  .subscribe[data-v-24eafc] {
    height: 40px;
    margin-top: 20px;
  }
}

a[data-v-5cbbd2] {
  all: unset;
  cursor: pointer;
}

.main-content[data-v-5cbbd2] {
  background-color: #fff;
  min-height: 16vh;
}

.button-pdf[data-v-5cbbd2] {
  color: #fff;
  letter-spacing: 2px;
  background: #323232;
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  white-space: 5px !important;
}

@media (width <= 400px) {
  .container-footer[data-v-5cbbd2] {
    flex-direction: column !important;
    place-content: center !important;
    display: flex !important;
  }

  .logo-footer[data-v-5cbbd2] {
    width: 80%;
  }

  .container-social[data-v-5cbbd2] {
    margin-top: 10px;
    place-content: center space-around !important;
    width: 80% !important;
    display: flex !important;
  }
}

.main-content[data-v-e5ef8b] {
  opacity: 1;
  background-image: url("headerBackground.7dd434aa.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 20vh;
}

.sectionDescription[data-v-e5ef8b] {
  color: #f2cd32;
  font-size: 32px;
  font-weight: bold;
}

@media (width <= 1000px) {
  .sectionTitle[data-v-e5ef8b] {
    font-size: 35px;
  }

  .sectionDescription[data-v-e5ef8b] {
    font-size: 18px;
  }
}

@media (width <= 700px) {
  .container-title[data-v-e5ef8b] {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }

  .container-section-title[data-v-e5ef8b] {
    flex-direction: column;
  }

  .separator-title[data-v-e5ef8b] {
    display: none;
  }
}

.main-content[data-v-d30f96] {
  background: #f6f6f6 no-repeat padding-box padding-box;
  min-height: 27vh;
}

.buttonText[data-v-d30f96] {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #fff;
}

.close-modal-button[data-v-7d9ecf] {
  top: 5px;
  right: 5px;
  position: absolute !important;
}

.celebration-icon[data-v-7d9ecf] {
  text-align: center;
  padding-top: 20px;
  font-size: 50px;
}

.congratulation-title[data-v-7d9ecf] {
  text-align: center;
  color: #167116;
  margin-top: 90px;
  font-size: 30px;
  font-weight: 900;
}

.congratulation-text[data-v-7d9ecf] {
  text-align: center;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 900;
}

.divisor-small[data-v-7d9ecf] {
  border: 1px solid #a59750;
  align-self: center;
  width: 50px;
  margin: 30px 0;
}

.cta-text[data-v-7d9ecf] {
  color: #353535;
  letter-spacing: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 100;
}

.cta-text span[data-v-7d9ecf] {
  font-weight: 900;
}

.banner-image[data-v-7d9ecf] {
  max-width: 100%;
  max-height: 100%;
}

.share-on-twitter-button[data-v-7d9ecf] {
  color: #fff;
  background: #1d9bf0;
  border: none;
  border-radius: 20px;
  height: 32px;
  font-weight: 700;
}

.twitter-icon[data-v-7d9ecf] {
  color: #fff;
  font-size: 15px;
}

@media (width <= 768px) {
  .banner-image[data-v-7d9ecf] {
    max-width: 100%;
    height: 300px;
    max-height: 100%;
  }

  .congratulation-title[data-v-7d9ecf] {
    margin-top: 40px;
  }
}

.background[data-v-ef72a9] {
  background: #70bb70 url("light-green-logo.4cdedca0.svg") -15% / 35% no-repeat padding-box padding-box;
  min-height: 50vh;
  padding: 0;
}

[data-v-ef72a9]::placeholder {
  color: #ebebeb;
  opacity: 1;
}

.start-today[data-v-ef72a9] {
  background: #d2c169;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: 11vw;
}

.container-img-cryptocampo[data-v-ef72a9] {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 0 !important;
}

.flex-column[data-v-ef72a9] {
  flex-direction: column;
}

.campaignBuy[data-v-ef72a9] {
  z-index: 2;
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 10px;
  height: 525px;
  position: relative;
  overflow: hidden;
}

.wallet-connected[data-v-ef72a9] {
  color: #70ba71;
  background-color: #353535;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 10px !important;
}

.campaignBuyConnectWallet[data-v-ef72a9] {
  z-index: 3;
  text-align: center;
  background: #e8e8e8;
  border-radius: 16px;
  width: 400px;
  height: 200px;
  margin: auto;
  padding: 30px 0;
  position: absolute;
  inset: 0;
}

.NFT-card[data-v-ef72a9] {
  background: url("NFTbackground.c0da232c.jpg");
  position: relative;
}

.bsc-logo-footer[data-v-ef72a9] {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.bsc-logo[data-v-ef72a9] {
  width: 25px;
  margin: auto;
  position: relative;
  top: 0;
  left: 0;
}

.buy-button[data-v-ef72a9]:enabled {
  color: #fff;
  background-color: #157116;
  border: 0;
  height: 73.5px;
  font-size: 17px;
  font-weight: 700;
  transition: font-size .5s;
}

.buy-button[data-v-ef72a9]:disabled {
  color: #fff;
  background-color: #c9c9c8;
  border: 0;
  height: 73.5px;
  font-size: 15px;
  font-weight: 400;
  transition: font-size .5s;
}

.log-in-first[data-v-ef72a9] {
  filter: blur();
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: filter 1s linear, -webkit-filter .25s linear;
}

.connectWallet[data-v-ef72a9] {
  background-color: #157116;
  border: 0;
  border-radius: 5px;
  padding: 8px 12px;
}

.connectWallet[data-v-ef72a9]:hover {
  background-color: #157116;
}

.campaignSoldOut[data-v-ef72a9] {
  z-index: 3;
  color: #f2ce31;
  text-align: center;
  background: #177116;
  border-radius: 16px;
  width: 125%;
  height: 200px;
  margin: auto auto auto -75px;
  padding: 20px 0;
  font-size: 100px;
  position: absolute;
  inset: 0;
  transform: rotate(-25deg);
}

.sold-out-subtitle[data-v-ef72a9] {
  color: #fff;
  margin-top: -15px;
  font-size: 20px;
  font-weight: 400;
}

@media (width <= 1500px) {
  .col-campaing[data-v-ef72a9] {
    width: 100%;
  }
}

@media (width <= 1350px) {
  .start-today[data-v-ef72a9] {
    padding-left: 7vw;
  }

  .campaignBuy[data-v-ef72a9] {
    justify-content: center;
    align-items: center;
    height: 600px;
    display: flex;
    margin-bottom: 0 !important;
  }
}

@media (width <= 1300px) {
  .box-info[data-v-ef72a9], .box-nft[data-v-ef72a9] {
    width: 100%;
  }

  .campaignBuy[data-v-ef72a9] {
    justify-content: center;
    align-items: center;
    height: 600px;
    display: flex;
    margin-bottom: 0 !important;
  }

  .col-campaing[data-v-ef72a9] {
    height: 600px;
  }

  .campaignInfo[data-v-ef72a9] {
    height: 100%;
    margin-bottom: 0 !important;
  }
}

@media (width <= 1200px) {
  .container-campaigns-page[data-v-ef72a9] {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .col-campaing[data-v-ef72a9] {
    height: 100%;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .campaignInfo[data-v-ef72a9] {
    padding-bottom: 24px;
  }

  .NFT-card[data-v-ef72a9] {
    margin-top: 35px;
    height: 500px !important;
  }
}

@media (width <= 1120px) {
  .campaignBuy[data-v-ef72a9] {
    justify-content: center;
    align-items: center;
    height: 650px;
    display: flex;
    margin-bottom: 0 !important;
  }
}

@media (width <= 1000px) {
  .container-nft-campaing[data-v-ef72a9] {
    height: 100%;
    min-width: 100% !important;
  }

  .container-img-cryptocampo[data-v-ef72a9] {
    height: 530px;
    padding: 0 !important;
  }

  .campaignBuy[data-v-ef72a9] {
    height: auto;
  }

  .container-btn-mint[data-v-ef72a9] {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .box-terms br[data-v-ef72a9] {
    display: none;
  }

  .box-terms[data-v-ef72a9] {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .buy-button[data-v-ef72a9] {
    margin-bottom: 15px;
    height: 55px !important;
    padding: 0 !important;
  }

  .NFT-card[data-v-ef72a9] {
    margin-top: 0;
  }
}

@media (width <= 750px) {
  .campaignSoldOut[data-v-ef72a9] {
    z-index: 3;
    color: #f2ce31;
    background: #177116;
    border-radius: 0;
    width: 100%;
    height: 100px;
    margin: auto;
    padding: 0;
    font-size: 50px;
    position: absolute;
    inset: 0;
    transform: rotate(0);
  }

  .sold-out-subtitle[data-v-ef72a9] {
    margin-top: -15px;
    font-size: 14px;
    font-weight: 200;
  }
}

@media (width <= 400px) {
  .campaignBuyConnectWallet[data-v-ef72a9] {
    width: 295px;
  }

  .container-nft-total[data-v-ef72a9], .container-service-free[data-v-ef72a9] {
    font-size: 10px;
  }
}

.section-padding {
  padding: 0 5vw;
}

.fixed-at-top {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
}

.top-spacer {
  padding-top: 6vh;
}

@media (width <= 1600px) {
  .section-padding {
    padding: 0 5vw;
  }
}

@media (width <= 1000px) {
  .top-spacer {
    padding-top: 48px;
  }
}

.main-content[data-v-617eb0] {
  background-color: #fff;
  min-height: 50vh;
}

.main-content[data-v-00f864] {
  background-color: #fff;
  min-height: 50vh;
}

.accordion-question[data-v-00f864] {
  background-color: #fff;
  border-top: .5px solid #d3d3d3;
}

.accordion-button[data-v-00f864]:not(.collapsed) {
  font-weight: 700;
  color: #000 !important;
  background-color: #fff !important;
}

.accordion-button[data-v-00f864]:link, .accordion-button[data-v-00f864]:visited, .accordion-button[data-v-00f864]:hover, .accordion-button[data-v-00f864]:active {
  font-weight: 700;
  color: #000 !important;
  box-shadow: 0px !important;
  background-color: #fff !important;
  border-color: #d3d3d3 !important;
  text-decoration: none !important;
}

.accordion-button[data-v-00f864]:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .1rem #70bb70 !important;
}

.accordion-button[data-v-00f864]:not(.collapsed):after {
  background-image: url("arrowFAQ-2.0e26dd4b.svg");
  transform: rotate(90deg);
}

.accordion-button[data-v-00f864]:after {
  background-image: url("arrowFAQ.1f2e482c.svg");
  height: 25px;
}

.accordion-border-button[data-v-00f864] {
  border-bottom: 1px solid #d3d3d3;
}

.search-button[data-v-00f864] {
  color: #fff;
  background-color: #70bb70;
  border: 0;
}

.search-button[data-v-00f864]:hover {
  color: #70bb70;
  background-color: #fff;
  border: 1px solid #70bb70;
}

.search-button[data-v-00f864]:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-control[data-v-00f864]:focus {
  border-color: #28a745 currentColor #28a745 #28a745;
  border-right-style: none;
  border-right-width: 0;
  box-shadow: none !important;
}

@media (width <= 1100px) {
  .title-faq[data-v-00f864] {
    padding: 0;
    font-size: 30px;
  }

  .container-input-search[data-v-00f864] {
    padding: 0;
  }
}

@media (width <= 400px) {
  .title-faq[data-v-00f864] {
    text-align: center;
    font-size: 25px;
  }

  .container-input-search[data-v-00f864] {
    padding: 0;
  }
}

.main-content[data-v-b44c0f] {
  background-color: #fff;
  min-height: 40vh;
}

.bannerTextColors[data-v-b44c0f] {
  color: #157116;
  font-weight: bold;
}

.bannerBoxSize[data-v-b44c0f] {
  width: 250px;
}

.bannerIcon[data-v-b44c0f] {
  background-color: #d3d3d3;
  border-radius: 50px;
  width: 75px;
  height: 75px;
  margin-right: 15px;
}

.about-image[data-v-b44c0f] {
  position: relative;
}

.about-image img[data-v-b44c0f] {
  position: absolute;
  right: -215px;
}

@media (width <= 1600px) {
  .about-image[data-v-b44c0f] {
    display: none;
  }
}

.campaigns-divider[data-v-6e4324] {
  color: #fff;
  background-color: #157116;
  width: 100%;
  margin: 40px 0 0;
  padding: 16px;
  font-family: Lato;
}

.campaigns-divider h3[data-v-6e4324] {
  font-size: 25px;
  font-weight: bold;
}

[data-v-c9053d] {
  font-size: Lato !important;
}

.accordion-question[data-v-c9053d] {
  background-color: #177116;
  border-top: .5px solid #d3d3d3;
  font-size: 2rem;
  font-weight: bold;
  color: #fff !important;
}

.accordion-button[data-v-c9053d]:not(.collapsed):after {
  background-image: url("arrow-campaign-2.0e26dd4b.svg");
  transform: rotate(90deg);
}

.accordion-button[data-v-c9053d]:after {
  background-image: url("arrow-campaign.0e26dd4b.svg");
  height: 25px;
}

.accordion-border-button[data-v-c9053d] {
  border-bottom: 1px solid #d3d3d3;
}

.index-whitepaper[data-v-c9053d] {
  position: sticky;
  top: 80px;
}

.main-content[data-v-c9053d] {
  background-color: #fff;
  min-height: 50vh;
}

.download[data-v-c9053d] {
  color: #f2cd32;
  font-weight: 400;
}

.img-previous-campaign-container[data-v-c9053d] {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.img-previous-campaign[data-v-c9053d] {
  object-fit: cover;
  width: 45%;
}

.how-invest-img[data-v-c9053d] {
  width: 100%;
  margin: 16px 0;
}

.pdf[data-v-c9053d] {
  color: #fff;
  font-weight: lighter;
}

a[data-v-c9053d], a[data-v-c9053d]:hover, a[data-v-c9053d]:focus, a[data-v-c9053d]:active {
  color: inherit;
  text-decoration: none;
}

.founders[data-v-c9053d] {
  border: 10px solid #177116;
  border-radius: 100%;
  width: 200px;
}

.founder-name[data-v-c9053d] {
  background-color: #177116;
  border: 0;
}

a:hover .fa-download[data-v-c9053d] {
  color: #71bb71;
}

.link-color[data-v-c9053d] {
  color: #2867c5;
}

.socialmedia-icons-container[data-v-c9053d] {
  justify-content: center;
  gap: 16px;
  margin-top: 48px;
  display: flex;
}

.socialmedia-link[data-v-c9053d] {
  transition: all .2s;
}

.socialmedia-link svg[data-v-c9053d] {
  fill: #a4338a;
  color: #a4338a;
  width: 35px;
  height: 35px;
  transition: all .2s;
}

.iglogo[data-v-c9053d] {
  fill: #a4338a;
  color: #a4338a;
  background-color: #a4338a;
}

.img-profit[data-v-c9053d] {
  border: 1px solid #177116;
  width: 50%;
}

@media (width <= 992px) {
  .index-whitepaper[data-v-c9053d] {
    position: initial;
  }

  .btn-pdf[data-v-c9053d] {
    width: 300px !important;
  }

  .img-whitepaper-map[data-v-c9053d], .img-whitepaper-graphic[data-v-c9053d], .img-whitepaper[data-v-c9053d] {
    width: 100% !important;
  }

  .img-previous-campaign-container[data-v-c9053d] {
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }

  .img-previous-campaign[data-v-c9053d] {
    width: 100%;
  }
}

.main-content[data-v-4a6020] {
  background: #f6f6f6 no-repeat padding-box padding-box;
  min-height: 27vh;
}

.buttonText[data-v-4a6020] {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #fff;
}

.container-img-cryptocampo[data-v-786219] {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 0 !important;
}

.main-content[data-v-786219] {
  background-color: #fff;
  min-height: 50vh;
}

.banner-width[data-v-786219] {
  width: 100%;
}

.flex-column[data-v-786219] {
  flex-direction: column;
}

.campaignInfo[data-v-786219] {
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 0 0 10px 10px;
  height: 525px;
}

.campaignInfoHeader[data-v-786219] {
  background-color: #157116;
  background-image: url("logo-nft-header.422b488f.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 35%;
  background-origin: content-box;
  border-radius: 10px 10px 0 0;
  padding: 40px 25px;
  font-size: 20px;
}

.campaignInfoHeaderTitle[data-v-786219] {
  color: #f2cd32;
}

.campaignInfoHeaderSubtitle[data-v-786219] {
  color: #fff;
  margin-top: 10px;
}

.campaignBuy[data-v-786219] {
  z-index: 2;
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 10px;
  height: 665px;
  position: relative;
  overflow: hidden;
}

.moreInfoButton[data-v-786219] {
  color: #fff;
  background: #d2c169;
  border: 0;
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
}

.moreInfoButton[data-v-786219]:hover {
  background: #a59750;
}

.wallet-connected[data-v-786219] {
  color: #70ba71;
  background-color: #353535;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 10px !important;
}

.campaignBuyConnectWallet[data-v-786219] {
  z-index: 3;
  text-align: center;
  background: #e8e8e8;
  border-radius: 16px;
  width: 400px;
  height: 200px;
  margin: auto;
  padding: 30px 0;
  position: absolute;
  inset: 0;
}

.campaignSoldOut[data-v-786219] {
  z-index: 3;
  color: #f2ce31;
  text-align: center;
  background: #177116;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 125%;
  height: 200px;
  margin: auto auto auto -75px;
  font-size: 100px;
  display: flex;
  position: absolute;
  inset: 0;
  transform: rotate(-25deg);
}

.sold-out-subtitle[data-v-786219] {
  color: #fff;
  margin-top: -15px;
  font-size: 20px;
  font-weight: 400;
}

.NFT-card[data-v-786219] {
  background: url("NFTbackground.c0da232c.jpg");
  position: relative;
}

.bsc-logo-footer[data-v-786219] {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.bsc-logo[data-v-786219] {
  width: 25px;
  margin: auto;
  position: relative;
  top: 0;
  left: 0;
}

.buy-button[data-v-786219]:enabled {
  color: #fff;
  background-color: #157116;
  border: 0;
  height: 73.5px;
  font-size: 17px;
  font-weight: 700;
  transition: font-size .5s;
}

.buy-button[data-v-786219]:disabled {
  color: #fff;
  background-color: #c9c9c8;
  border: 0;
  height: 73.5px;
  font-size: 15px;
  font-weight: 400;
  transition: font-size .5s;
}

.log-in-first[data-v-786219] {
  filter: blur();
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: filter 1s linear, -webkit-filter .25s linear;
}

.connectWallet[data-v-786219] {
  background-color: #157116;
  border: 0;
  border-radius: 5px;
  padding: 8px 12px;
}

.connectWallet[data-v-786219]:hover {
  background-color: #157116;
}

.user-select-auto[data-v-786219] {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.accordion-question[data-v-786219] {
  background-color: #177116;
  border-top: .5px solid #d3d3d3;
  font-size: 2rem;
  font-weight: bold;
  color: #fff !important;
}

.accordion-button[data-v-786219]:not(.collapsed):after {
  background-image: url("arrow-campaign-2.0e26dd4b.svg");
  transform: rotate(90deg);
}

.accordion-button[data-v-786219]:after {
  background-image: url("arrow-campaign.0e26dd4b.svg");
  height: 25px;
}

.accordion-border-button[data-v-786219] {
  border-bottom: 1px solid #d3d3d3;
}

.bg-grey[data-v-786219] {
  background-color: #353535;
}

.font-2rem[data-v-786219] {
  font-size: 25px !important;
}

.text-black[data-v-786219] {
  color: "#000000";
}

@media (width <= 1900px) {
  .campaignSoldOut[data-v-786219] {
    text-align: center;
    width: 125%;
    height: 200px;
    margin-left: -100px;
    font-size: 100px;
  }
}

@media (width <= 1800px) {
  .container-img-cryptocampo[data-v-786219] {
    padding: 20px 0 0 20px;
  }
}

@media (width <= 1700px) {
  .campaignInfo[data-v-786219] {
    height: 100%;
    padding-bottom: 24px;
    margin-bottom: 0 !important;
  }
}

@media (width <= 1600px) {
  .container-info-mint[data-v-786219] {
    width: 60% !important;
    padding: 20px 10px 10px !important;
  }

  .container-img-cryptocampo[data-v-786219] {
    padding: 20px 0 0 10px;
    width: 40% !important;
  }

  .campaignInfo[data-v-786219] {
    height: 650px;
  }

  .campaignBuy[data-v-786219] {
    height: 100%;
  }
}

@media (width <= 1300px) {
  .campaignInfo[data-v-786219] {
    height: 350px;
  }

  .campaignBuy[data-v-786219] {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 0 !important;
  }

  .container-campaigns-page[data-v-786219] {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .col-campaing[data-v-786219] {
    width: 100% !important;
  }

  .container-info-mint[data-v-786219], .container-img-cryptocampo[data-v-786219] {
    padding: 1.5rem !important;
  }
}

@media (width <= 1200px) {
  .col-campaing[data-v-786219] {
    height: 100%;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
}

@media (width <= 1000px) {
  .campaignInfo[data-v-786219] {
    height: 450px;
  }

  .container-nft-campaing[data-v-786219] {
    height: 100%;
    min-width: 100% !important;
  }

  .campaignBuy[data-v-786219] {
    height: auto;
  }

  .container-btn-mint[data-v-786219] {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .box-terms br[data-v-786219] {
    display: none;
  }

  .box-terms[data-v-786219] {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .buy-button[data-v-786219] {
    margin-bottom: 15px;
    height: 55px !important;
    padding: 0 !important;
  }

  .container-info-mint[data-v-786219], .container-img-cryptocampo[data-v-786219] {
    padding: 20px !important;
  }
}

@media (width <= 900px) {
  .container-info-mint[data-v-786219], .container-img-cryptocampo[data-v-786219] {
    width: 100% !important;
  }
}

@media (width <= 990px) {
  .campaignSoldOut[data-v-786219] {
    text-align: center;
    border-radius: 16px;
    width: 135%;
    height: 200px;
    margin-left: -100px;
    font-size: 100px;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    transform: rotate(-25deg);
  }
}

@media (width <= 650px) {
  .campaignInfo[data-v-786219] {
    height: 750px;
  }
}

@media (width <= 600px) {
  .campaignSoldOut[data-v-786219] {
    text-align: center;
    border-radius: 16px;
    width: 145%;
    height: 100px;
    margin-left: -100px;
    font-size: 50px;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    transform: rotate(-25deg);
  }
}

@media (width <= 375px) {
  .campaignSoldOut[data-v-786219] {
    text-align: center;
    border-radius: 16px;
    width: 160%;
    height: 100px;
    margin-left: -100px;
    font-size: 50px;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    transform: rotate(-25deg);
  }
}

@media (width <= 400px) {
  .campaignBuyConnectWallet[data-v-786219] {
    width: 295px;
  }

  .container-nft-total[data-v-786219], .container-service-free[data-v-786219] {
    font-size: 10px;
  }
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow svg {
  fill: #000;
  width: 1.2em;
  height: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: .9;
}

.splide__arrow:disabled {
  opacity: .3;
}

.splide__arrow:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__pagination {
  z-index: 1;
  padding: 0 1em;
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  opacity: .7;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  display: inline-block;
  position: relative;
}

.splide__pagination__page.is-active {
  z-index: 1;
  background: #fff;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
}

.splide__pagination__page:focus-visible, .splide.is-focus-in .splide__pagination__page:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: #0000;
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}

@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}

.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible, .splide.is-focus-in .splide__toggle:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__track--nav > .splide__list > .splide__slide {
  cursor: pointer;
  border: 3px solid #0000;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  inset: 0 .5em 0 auto;
}

.main-content[data-v-6b86a2] {
  background-color: #fff;
  min-height: 50vh;
}

.flex-column[data-v-6b86a2] {
  flex-direction: column;
}

.custom-arrows[data-v-6b86a2] {
  color: #147116;
  opacity: 1;
  background-color: #70bb70;
  border-radius: 5px;
  width: 75px;
  height: 100%;
  font-size: 50px;
  position: relative;
}

.container-info[data-v-6b86a2] {
  min-width: 150px;
}

@media (width <= 1800px) {
  .splide-container[data-v-6b86a2] {
    padding-right: 100px;
  }

  .splide-container-2[data-v-6b86a2] {
    padding-left: 100px;
  }
}

@media (width <= 1700px) {
  .splide-container[data-v-6b86a2] {
    padding-right: 200px;
  }
}

@media (width <= 1600px) {
  .splide-container[data-v-6b86a2] {
    padding-right: 100px;
  }
}

@media (width <= 1500px) {
  .splide-container[data-v-6b86a2] {
    padding-right: 200px;
  }
}

@media (width <= 1400px) {
  .splide-container[data-v-6b86a2] {
    padding-right: 0;
  }

  .img-campaign[data-v-6b86a2] {
    width: 80%;
  }

  .img-prod[data-v-6b86a2] {
    width: 100%;
  }
}

@media (width <= 1300px) {
  .container-info[data-v-6b86a2] {
    min-width: auto;
  }

  .container-info div[data-v-6b86a2] {
    text-align: center;
  }

  .container-info-campaign[data-v-6b86a2] {
    text-align: center;
    justify-content: start;
    align-items: center;
    flex-direction: column !important;
    display: flex !important;
  }

  .separator-campaign-info[data-v-6b86a2] {
    display: none;
  }

  .splide-container-2[data-v-6b86a2] {
    padding-left: 80px;
  }
}

@media (width <= 1023px) {
  .custom-arrows[data-v-6b86a2] {
    width: 20px;
  }

  .splide__arrow svg[data-v-6b86a2] {
    height: 35px;
  }
}

@media (width <= 400px) {
  .container-inf-campaign[data-v-6b86a2] {
    flex-direction: column !important;
    display: flex !important;
  }

  .container-info-campaign[data-v-6b86a2] {
    width: 100% !important;
  }

  .title-info-campaign[data-v-6b86a2] {
    text-align: center;
    font-size: 20px;
  }

  .container-prod-1[data-v-6b86a2] {
    padding-left: 0;
  }

  .splide-container[data-v-6b86a2] {
    padding-right: 10px;
  }

  .splide-container-2[data-v-6b86a2] {
    padding-left: 10px;
  }
}

.main-content[data-v-2eaba1] {
  background-color: #fff;
  min-height: 50vh;
}

.flex-column[data-v-2eaba1] {
  flex-direction: column;
}

.custom-arrows[data-v-2eaba1] {
  color: #147116;
  opacity: 1;
  background-color: #70bb70;
  border-radius: 5px;
  width: 75px;
  height: 100%;
  font-size: 50px;
  position: relative;
}

.container-info[data-v-2eaba1] {
  min-width: 150px;
}

.img-campaign[data-v-2eaba1] {
  width: 100%;
  height: 40%;
}

@media (width <= 1800px) {
  .splide-container[data-v-2eaba1] {
    padding-right: 100px;
  }

  .splide-container-2[data-v-2eaba1] {
    padding-left: 100px;
  }
}

@media (width <= 1700px) {
  .splide-container[data-v-2eaba1] {
    padding-right: 200px;
  }
}

@media (width <= 1600px) {
  .splide-container[data-v-2eaba1] {
    padding-right: 100px;
  }
}

@media (width <= 1500px) {
  .splide-container[data-v-2eaba1] {
    padding-right: 200px;
  }
}

@media (width <= 1400px) {
  .splide-container[data-v-2eaba1] {
    padding-right: 0;
  }

  .img-campaign[data-v-2eaba1] {
    width: 80%;
  }

  .img-prod[data-v-2eaba1] {
    width: 100%;
  }
}

@media (width <= 1300px) {
  .container-info[data-v-2eaba1] {
    min-width: auto;
  }

  .container-info div[data-v-2eaba1] {
    text-align: center;
  }

  .container-info-campaign[data-v-2eaba1] {
    text-align: center;
    justify-content: start;
    align-items: center;
    flex-direction: column !important;
    display: flex !important;
  }

  .separator-campaign-info[data-v-2eaba1] {
    display: none;
  }

  .splide-container-2[data-v-2eaba1] {
    padding-left: 80px;
  }
}

@media (width <= 1023px) {
  .custom-arrows[data-v-2eaba1] {
    width: 20px;
  }

  .splide__arrow svg[data-v-2eaba1] {
    height: 35px;
  }
}

@media (width <= 400px) {
  .container-inf-campaign[data-v-2eaba1] {
    flex-direction: column !important;
    display: flex !important;
  }

  .container-info-campaign[data-v-2eaba1] {
    width: 100% !important;
  }

  .title-info-campaign[data-v-2eaba1] {
    text-align: center;
    font-size: 20px;
  }

  .container-prod-1[data-v-2eaba1] {
    padding-left: 0;
  }

  .splide-container[data-v-2eaba1] {
    padding-right: 10px;
  }

  .splide-container-2[data-v-2eaba1] {
    padding-left: 10px;
  }
}

.main-content[data-v-f7e9b5] {
  background-color: #fff;
  min-height: 50vh;
}

.flex-column[data-v-f7e9b5] {
  flex-direction: column;
}

.campaignBuy-header[data-v-f7e9b5] {
  z-index: 2;
  background: #157116 url("logo-nft-header.422b488f.svg") 100% no-repeat content-box border-box;
  border-radius: 10px 10px 0 0;
  height: auto;
  position: relative;
}

.campaignBuy[data-v-f7e9b5] {
  z-index: 2;
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 0 0 10px 10px;
  height: auto;
  position: relative;
}

.bsc-logo-footer[data-v-f7e9b5] {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.buy-button[data-v-f7e9b5]:enabled {
  color: #fff;
  background-color: #157116;
  border: 0;
  height: 73.5px;
  font-size: 17px;
  font-weight: 700;
  transition: font-size .5s;
}

.buy-button[data-v-f7e9b5]:disabled {
  color: #fff;
  background-color: #c9c9c8;
  border: 0;
  height: 73.5px;
  font-size: 15px;
  font-weight: 400;
  transition: font-size .5s;
}

.container-img-cryptocampo[data-v-f7e9b5] {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 !important;
}

@media (width <= 1400px) {
  .container-my-investments[data-v-f7e9b5] {
    width: 100%;
  }
}

@media (width <= 1000px) {
  .box-nft[data-v-f7e9b5] {
    margin-left: auto;
    margin-right: auto;
  }

  .container-img-cryptocampo[data-v-f7e9b5] {
    height: 530px;
    padding-right: 1.5rem !important;
  }

  .campaignBuy[data-v-f7e9b5] {
    height: auto;
  }

  .buy-button[data-v-f7e9b5] {
    margin-bottom: 15px;
    height: 55px !important;
    padding: 0 !important;
  }

  @media (width <= 400px) {
    .container-my-investments-page[data-v-f7e9b5] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .campaign-header[data-v-f7e9b5] {
      flex-direction: column;
    }

    .title-campaign[data-v-f7e9b5] {
      font-size: 20px !important;
    }

    .text-campaign[data-v-f7e9b5] {
      margin-bottom: 20px;
      font-size: 15px !important;
    }
  }
}

.main-content[data-v-a36e2d] {
  background-color: #fff;
  min-height: 50vh;
}

.flex-column[data-v-a36e2d] {
  flex-direction: column;
}

.campaignBuy-header[data-v-a36e2d] {
  z-index: 2;
  background: #157116 url("logo-nft-header.422b488f.svg") 100% no-repeat content-box border-box;
  border-radius: 10px 10px 0 0;
  height: auto;
  position: relative;
}

.campaignBuy[data-v-a36e2d] {
  z-index: 2;
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 0 0 10px 10px;
  height: auto;
  position: relative;
}

.bsc-logo-footer[data-v-a36e2d] {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.buy-button[data-v-a36e2d]:enabled {
  color: #fff;
  background-color: #157116;
  border: 0;
  height: 73.5px;
  font-size: 17px;
  font-weight: 700;
  transition: font-size .5s;
}

.buy-button[data-v-a36e2d]:disabled {
  color: #fff;
  background-color: #c9c9c8;
  border: 0;
  height: 73.5px;
  font-size: 15px;
  font-weight: 400;
  transition: font-size .5s;
}

.container-img-cryptocampo[data-v-a36e2d] {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 !important;
}

@media (width <= 1400px) {
  .container-my-investments[data-v-a36e2d] {
    width: 100%;
  }
}

@media (width <= 1000px) {
  .box-nft[data-v-a36e2d] {
    margin-left: auto;
    margin-right: auto;
  }

  .container-img-cryptocampo[data-v-a36e2d] {
    height: 530px;
    padding-right: 1.5rem !important;
  }

  .campaignBuy[data-v-a36e2d] {
    height: auto;
  }

  .buy-button[data-v-a36e2d] {
    margin-bottom: 15px;
    height: 55px !important;
    padding: 0 !important;
  }

  @media (width <= 400px) {
    .container-my-investments-page[data-v-a36e2d] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .campaign-header[data-v-a36e2d] {
      flex-direction: column;
    }

    .title-campaign[data-v-a36e2d] {
      font-size: 20px !important;
    }

    .text-campaign[data-v-a36e2d] {
      margin-bottom: 20px;
      font-size: 15px !important;
    }
  }
}

.main-content[data-v-41e526] {
  background-color: #fff;
  min-height: 50vh;
}

.campaign2-main-content[data-v-41e526] {
  padding: 0 16px;
}

.h2-black[data-v-41e526] {
  color: #353535;
}

.h3-green[data-v-41e526] {
  color: #177116;
}

.accordion-question[data-v-41e526] {
  background-color: #177116;
  border-top: .5px solid #d3d3d3;
  font-size: 25px;
  color: #fff !important;
}

.accordion-button[data-v-41e526]:not(.collapsed) {
  color: #fff !important;
  background-color: #177116 !important;
}

.accordion-button[data-v-41e526]:link, .accordion-button[data-v-41e526]:visited, .accordion-button[data-v-41e526]:hover, .accordion-button[data-v-41e526]:active {
  color: #fff !important;
  box-shadow: 0px !important;
  background-color: #177116 !important;
  border-color: #d3d3d3 !important;
  text-decoration: none !important;
}

.accordion-button[data-v-41e526]:focus {
  z-index: 3;
  outline: 0;
  color: #fff !important;
  box-shadow: 0 0 0 .1rem #70bb70 !important;
}

.accordion-button[data-v-41e526]:not(.collapsed):after {
  background-image: url("arrow-campaign-2.0e26dd4b.svg");
  transform: rotate(90deg);
}

.accordion-button[data-v-41e526]:after {
  background-image: url("arrow-campaign.0e26dd4b.svg");
  height: 25px;
}

.accordion-border-button[data-v-41e526] {
  border-bottom: 1px solid #d3d3d3;
}

.button-disabled[data-v-41e526]:not(.collapsed) {
  font-size: 25px;
  color: #b4b4b4 !important;
  background-color: #e8e8e8 !important;
}

.button-disabled[data-v-41e526]:link, .button-disabled[data-v-41e526]:visited, .button-disabled[data-v-41e526]:hover, .button-disabled[data-v-41e526]:active {
  color: #b4b4b4 !important;
  box-shadow: 0px !important;
  background-color: #e8e8e8 !important;
  border-color: #d3d3d3 !important;
  text-decoration: none !important;
}

.button-disabled[data-v-41e526]:focus {
  z-index: 3;
  outline: 0;
  color: #b4b4b4 !important;
  box-shadow: 0 0 !important;
}

.button-disabled[data-v-41e526]:not(.collapsed):after {
  background-image: url("disabled-arrow-2.bab0f47b.svg");
  transform: rotate(0);
}

.button-disabled[data-v-41e526]:after {
  background-image: url("disabled-arrow-1.bab0f47b.svg");
  height: 25px;
}

.campaignInfo[data-v-41e526] {
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 0 0 10px 10px;
}

.campaignInfoHeader[data-v-41e526] {
  background-color: #157116;
  background-image: url("logo-nft-header.422b488f.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 35%;
  background-origin: content-box;
  border-radius: 10px 10px 0 0;
  padding: 25px;
  font-size: 20px;
}

.campaignInfoHeaderTitle[data-v-41e526] {
  color: #f2cd32;
}

.campaignInfoHeaderSubtitle[data-v-41e526] {
  color: #fff;
  margin-top: 10px;
  font-size: 18px;
}

.NFT-card[data-v-41e526] {
  background: url("NFTbackground.c0da232c.jpg");
  position: relative;
  border-radius: 5px !important;
}

.bsc-logo-footer[data-v-41e526] {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.accordion-bg[data-v-41e526] {
  background-color: #f8f8f8;
}

.claim-button[data-v-41e526] {
  color: #fff;
  background-color: #177116;
  width: 18vw;
  margin: 10px;
}

.card-height[data-v-41e526] {
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-align[data-v-41e526] {
  align-self: center;
}

.card-info-text[data-v-41e526] {
  font-size: 18px;
}

.btn[data-v-41e526]:disabled {
  color: #fff;
  background-color: #c8c8c8;
  border: 0;
}

.btn[data-v-41e526] {
  color: #fff;
  background-color: #177116;
}

.container-nft[data-v-41e526] {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (width <= 1500px) {
  .container-follow-campaign[data-v-41e526] {
    flex-direction: column-reverse;
    display: flex;
  }

  .box-nft[data-v-41e526], .box-nft-info[data-v-41e526] {
    width: 100%;
  }
}

@media (width <= 1200px) {
  .container-title-campaign div[data-v-41e526] {
    font-size: 24px !important;
  }

  .container-nft-info[data-v-41e526] {
    flex-direction: column;
  }
}

@media (width <= 992px) {
  .claim-button[data-v-41e526] {
    width: 200px;
  }
}

@media (width <= 376px) {
  .claim-button[data-v-41e526] {
    width: 80%;
  }

  .container-title-campaign[data-v-41e526] {
    flex-direction: column;
    padding-top: 1rem !important;
  }

  .container-title-campaign div[data-v-41e526] {
    padding-left: 0 !important;
    font-size: 16px !important;
  }

  .container-preparation-stage[data-v-41e526] {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

.main-content[data-v-d8a2ef] {
  background-color: #fff;
  min-height: 50vh;
}

.campaign2-main-content[data-v-d8a2ef] {
  padding: 0 16px;
}

.h2-black[data-v-d8a2ef] {
  color: #353535;
}

.h3-green[data-v-d8a2ef] {
  color: #177116;
}

.accordion-question[data-v-d8a2ef] {
  background-color: #177116;
  border-top: .5px solid #d3d3d3;
  font-size: 25px;
  color: #fff !important;
}

.accordion-button[data-v-d8a2ef]:not(.collapsed) {
  color: #fff !important;
  background-color: #177116 !important;
}

.accordion-button[data-v-d8a2ef]:link, .accordion-button[data-v-d8a2ef]:visited, .accordion-button[data-v-d8a2ef]:hover, .accordion-button[data-v-d8a2ef]:active {
  color: #fff !important;
  box-shadow: 0px !important;
  background-color: #177116 !important;
  border-color: #d3d3d3 !important;
  text-decoration: none !important;
}

.accordion-button[data-v-d8a2ef]:focus {
  z-index: 3;
  outline: 0;
  color: #fff !important;
  box-shadow: 0 0 0 .1rem #70bb70 !important;
}

.accordion-button[data-v-d8a2ef]:not(.collapsed):after {
  background-image: url("arrow-campaign-2.0e26dd4b.svg");
  transform: rotate(90deg);
}

.accordion-button[data-v-d8a2ef]:after {
  background-image: url("arrow-campaign.0e26dd4b.svg");
  height: 25px;
}

.accordion-border-button[data-v-d8a2ef] {
  border-bottom: 1px solid #d3d3d3;
}

.button-disabled[data-v-d8a2ef]:not(.collapsed) {
  font-size: 25px;
  color: #b4b4b4 !important;
  background-color: #e8e8e8 !important;
}

.button-disabled[data-v-d8a2ef]:link, .button-disabled[data-v-d8a2ef]:visited, .button-disabled[data-v-d8a2ef]:hover, .button-disabled[data-v-d8a2ef]:active {
  color: #b4b4b4 !important;
  box-shadow: 0px !important;
  background-color: #e8e8e8 !important;
  border-color: #d3d3d3 !important;
  text-decoration: none !important;
}

.button-disabled[data-v-d8a2ef]:focus {
  z-index: 3;
  outline: 0;
  color: #b4b4b4 !important;
  box-shadow: 0 0 !important;
}

.button-disabled[data-v-d8a2ef]:not(.collapsed):after {
  background-image: url("disabled-arrow-2.bab0f47b.svg");
  transform: rotate(0);
}

.button-disabled[data-v-d8a2ef]:after {
  background-image: url("disabled-arrow-1.bab0f47b.svg");
  height: 25px;
}

.campaignInfo[data-v-d8a2ef] {
  background: #e8e8e8;
  border-bottom: 15px solid #f2cd32;
  border-radius: 0 0 10px 10px;
}

.campaignInfoHeader[data-v-d8a2ef] {
  background-color: #157116;
  background-image: url("logo-nft-header.422b488f.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 35%;
  background-origin: content-box;
  border-radius: 10px 10px 0 0;
  padding: 25px;
  font-size: 20px;
}

.campaignInfoHeaderTitle[data-v-d8a2ef] {
  color: #f2cd32;
}

.campaignInfoHeaderSubtitle[data-v-d8a2ef] {
  color: #fff;
  margin-top: 10px;
  font-size: 18px;
}

.NFT-card[data-v-d8a2ef] {
  background: url("NFTbackground.c0da232c.jpg");
  position: relative;
  border-radius: 5px !important;
}

.bsc-logo-footer[data-v-d8a2ef] {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.accordion-bg[data-v-d8a2ef] {
  background-color: #f8f8f8;
}

.claim-button[data-v-d8a2ef] {
  color: #fff;
  background-color: #177116;
  width: 18vw;
  margin: 10px;
}

.card-height[data-v-d8a2ef] {
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-align[data-v-d8a2ef] {
  align-self: center;
}

.card-info-text[data-v-d8a2ef] {
  font-size: 18px;
}

.btn[data-v-d8a2ef]:disabled {
  color: #fff;
  background-color: #c8c8c8;
  border: 0;
}

.btn[data-v-d8a2ef] {
  color: #fff;
  background-color: #177116;
}

.container-nft[data-v-d8a2ef] {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (width <= 1500px) {
  .container-follow-campaign[data-v-d8a2ef] {
    flex-direction: column-reverse;
    display: flex;
  }

  .box-nft[data-v-d8a2ef], .box-nft-info[data-v-d8a2ef] {
    width: 100%;
  }
}

@media (width <= 1200px) {
  .container-title-campaign div[data-v-d8a2ef] {
    font-size: 24px !important;
  }

  .container-nft-info[data-v-d8a2ef] {
    flex-direction: column;
  }
}

@media (width <= 992px) {
  .claim-button[data-v-d8a2ef] {
    width: 200px;
  }
}

@media (width <= 376px) {
  .claim-button[data-v-d8a2ef] {
    width: 80%;
  }

  .container-title-campaign[data-v-d8a2ef] {
    flex-direction: column;
    padding-top: 1rem !important;
  }

  .container-title-campaign div[data-v-d8a2ef] {
    padding-left: 0 !important;
    font-size: 16px !important;
  }

  .container-preparation-stage[data-v-d8a2ef] {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

.main-content[data-v-9f64a8] {
  background-color: #fff;
  min-height: 50vh;
}

.main-content[data-v-52e70f] {
  background-color: #fff;
  min-height: 50vh;
}

.main-content[data-v-7470ec] {
  background-color: #fff;
  min-height: 50vh;
}

.whatsapp[data-v-7470ec] {
  object-fit: contain;
  background-color: #30c54d;
  width: 252px;
  height: 91px;
  margin: 10px;
}

.discord[data-v-7470ec] {
  object-fit: contain;
  background-color: #5865f2;
  width: 252px;
  height: 91px;
  margin: 10px;
}

.linkedin[data-v-7470ec] {
  object-fit: contain;
  background-color: #0966c2;
  width: 160px;
  height: 91px;
  margin: 10px;
}

.ig[data-v-7470ec] {
  object-fit: contain;
  background: linear-gradient(159deg, #6e69fa 35%, #c44ba3 67%, #df656c 85% 91%, #df656c 95%, #cf4e93 100%);
  width: 160px;
  height: 91px;
  margin: 10px;
}

.twitter[data-v-7470ec] {
  object-fit: contain;
  background-color: #2297eb;
  width: 160px;
  height: 91px;
  margin: 10px;
}

.btn[data-v-7470ec]:hover, .btn[data-v-7470ec]:focus {
  color: #fff;
  background-color: #157116;
}

@media (width <= 420px) {
  .container-social[data-v-7470ec] {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-social[data-v-7470ec] {
    width: 200px;
  }
}

.Vue-Toastification__container {
  z-index: 9999;
  box-sizing: border-box;
  color: #fff;
  pointer-events: none;
  flex-direction: column;
  width: 600px;
  min-height: 100%;
  padding: 4px;
  display: flex;
  position: fixed;
}

@media only screen and (width >= 600px) {
  .Vue-Toastification__container.top-left, .Vue-Toastification__container.top-right, .Vue-Toastification__container.top-center {
    top: 1em;
  }

  .Vue-Toastification__container.bottom-left, .Vue-Toastification__container.bottom-right, .Vue-Toastification__container.bottom-center {
    flex-direction: column-reverse;
    bottom: 1em;
  }

  .Vue-Toastification__container.top-left, .Vue-Toastification__container.bottom-left {
    left: 1em;
  }

  .Vue-Toastification__container.top-left .Vue-Toastification__toast, .Vue-Toastification__container.bottom-left .Vue-Toastification__toast {
    margin-right: auto;
  }

  @supports not ((-moz-appearance: none)) {
    .Vue-Toastification__container.top-left .Vue-Toastification__toast--rtl, .Vue-Toastification__container.bottom-left .Vue-Toastification__toast--rtl {
      margin-right: unset;
      margin-left: auto;
    }
  }

  .Vue-Toastification__container.top-right, .Vue-Toastification__container.bottom-right {
    right: 1em;
  }

  .Vue-Toastification__container.top-right .Vue-Toastification__toast, .Vue-Toastification__container.bottom-right .Vue-Toastification__toast {
    margin-left: auto;
  }

  @supports not ((-moz-appearance: none)) {
    .Vue-Toastification__container.top-right .Vue-Toastification__toast--rtl, .Vue-Toastification__container.bottom-right .Vue-Toastification__toast--rtl {
      margin-left: unset;
      margin-right: auto;
    }
  }

  .Vue-Toastification__container.top-center, .Vue-Toastification__container.bottom-center {
    margin-left: -300px;
    left: 50%;
  }

  .Vue-Toastification__container.top-center .Vue-Toastification__toast, .Vue-Toastification__container.bottom-center .Vue-Toastification__toast {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (width <= 600px) {
  .Vue-Toastification__container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Vue-Toastification__container .Vue-Toastification__toast {
    width: 100%;
  }

  .Vue-Toastification__container.top-left, .Vue-Toastification__container.top-right, .Vue-Toastification__container.top-center {
    top: 0;
  }

  .Vue-Toastification__container.bottom-left, .Vue-Toastification__container.bottom-right, .Vue-Toastification__container.bottom-center {
    flex-direction: column-reverse;
    bottom: 0;
  }
}

.Vue-Toastification__toast {
  box-sizing: border-box;
  pointer-events: auto;
  direction: ltr;
  border-radius: 8px;
  justify-content: space-between;
  min-width: 326px;
  max-width: 600px;
  min-height: 64px;
  max-height: 800px;
  margin-bottom: 1rem;
  padding: 22px 24px;
  font-family: Lato, Helvetica, Roboto, Arial, sans-serif;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Vue-Toastification__toast--rtl {
  direction: rtl;
}

.Vue-Toastification__toast--default {
  color: #fff;
  background-color: #1976d2;
}

.Vue-Toastification__toast--info {
  color: #fff;
  background-color: #2196f3;
}

.Vue-Toastification__toast--success {
  color: #fff;
  background-color: #4caf50;
}

.Vue-Toastification__toast--error {
  color: #fff;
  background-color: #ff5252;
}

.Vue-Toastification__toast--warning {
  color: #fff;
  background-color: #ffc107;
}

@media only screen and (width <= 600px) {
  .Vue-Toastification__toast {
    border-radius: 0;
    margin-bottom: .5rem;
  }
}

.Vue-Toastification__toast-body {
  word-break: break-word;
  white-space: pre-wrap;
  flex: 1;
  font-size: 16px;
  line-height: 24px;
}

.Vue-Toastification__toast-component-body {
  flex: 1;
}

.Vue-Toastification__toast.disable-transition {
  animation: none !important;
}

.Vue-Toastification__close-button {
  cursor: pointer;
  color: #fff;
  opacity: .3;
  background: none;
  border: none;
  outline: none;
  align-items: center;
  padding: 0 0 0 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  transition: visibility, opacity .2s linear;
}

.Vue-Toastification__close-button:hover, .Vue-Toastification__close-button:focus {
  opacity: 1;
}

.Vue-Toastification__toast:not(:hover) .Vue-Toastification__close-button.show-on-hover {
  opacity: 0;
}

.Vue-Toastification__toast--rtl .Vue-Toastification__close-button {
  padding-left: unset;
  padding-right: 10px;
}

@keyframes scale-x-frames {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Vue-Toastification__progress-bar {
  z-index: 10000;
  transform-origin: 0;
  background-color: #ffffffb3;
  width: 100%;
  height: 5px;
  animation: linear forwards scale-x-frames;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Vue-Toastification__toast--rtl .Vue-Toastification__progress-bar {
  right: 0;
  left: unset;
  transform-origin: 100%;
}

.Vue-Toastification__icon {
  background: none;
  border: none;
  outline: none;
  align-items: center;
  width: 20px;
  height: 100%;
  margin: auto 18px auto 0;
  padding: 0;
  transition: all .3s;
}

.Vue-Toastification__toast--rtl .Vue-Toastification__icon {
  margin: auto 0 auto 18px;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes bounceOutRight {
  40% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Vue-Toastification__bounce-enter-active.top-left, .Vue-Toastification__bounce-enter-active.bottom-left {
  animation-name: bounceInLeft;
}

.Vue-Toastification__bounce-enter-active.top-right, .Vue-Toastification__bounce-enter-active.bottom-right {
  animation-name: bounceInRight;
}

.Vue-Toastification__bounce-enter-active.top-center {
  animation-name: bounceInDown;
}

.Vue-Toastification__bounce-enter-active.bottom-center {
  animation-name: bounceInUp;
}

.Vue-Toastification__bounce-leave-active:not(.disable-transition).top-left, .Vue-Toastification__bounce-leave-active:not(.disable-transition).bottom-left {
  animation-name: bounceOutLeft;
}

.Vue-Toastification__bounce-leave-active:not(.disable-transition).top-right, .Vue-Toastification__bounce-leave-active:not(.disable-transition).bottom-right {
  animation-name: bounceOutRight;
}

.Vue-Toastification__bounce-leave-active:not(.disable-transition).top-center {
  animation-name: bounceOutUp;
}

.Vue-Toastification__bounce-leave-active:not(.disable-transition).bottom-center {
  animation-name: bounceOutDown;
}

.Vue-Toastification__bounce-leave-active, .Vue-Toastification__bounce-enter-active {
  animation-duration: .75s;
  animation-fill-mode: both;
}

.Vue-Toastification__bounce-move {
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
}

@keyframes fadeOutTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@keyframes fadeOutBottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Vue-Toastification__fade-enter-active.top-left, .Vue-Toastification__fade-enter-active.bottom-left {
  animation-name: fadeInLeft;
}

.Vue-Toastification__fade-enter-active.top-right, .Vue-Toastification__fade-enter-active.bottom-right {
  animation-name: fadeInRight;
}

.Vue-Toastification__fade-enter-active.top-center {
  animation-name: fadeInTop;
}

.Vue-Toastification__fade-enter-active.bottom-center {
  animation-name: fadeInBottom;
}

.Vue-Toastification__fade-leave-active:not(.disable-transition).top-left, .Vue-Toastification__fade-leave-active:not(.disable-transition).bottom-left {
  animation-name: fadeOutLeft;
}

.Vue-Toastification__fade-leave-active:not(.disable-transition).top-right, .Vue-Toastification__fade-leave-active:not(.disable-transition).bottom-right {
  animation-name: fadeOutRight;
}

.Vue-Toastification__fade-leave-active:not(.disable-transition).top-center {
  animation-name: fadeOutTop;
}

.Vue-Toastification__fade-leave-active:not(.disable-transition).bottom-center {
  animation-name: fadeOutBottom;
}

.Vue-Toastification__fade-leave-active, .Vue-Toastification__fade-enter-active {
  animation-duration: .75s;
  animation-fill-mode: both;
}

.Vue-Toastification__fade-move {
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
}

@keyframes slideInBlurredLeft {
  0% {
    transform-origin: 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(-1000px)scaleX(2.5)scaleY(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }
}

@keyframes slideInBlurredTop {
  0% {
    transform-origin: 50% 0;
    filter: blur(240px);
    opacity: 0;
    transform: translateY(-1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

@keyframes slideInBlurredRight {
  0% {
    transform-origin: 0%;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(1000px)scaleX(2.5)scaleY(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }
}

@keyframes slideInBlurredBottom {
  0% {
    transform-origin: 50% 100%;
    filter: blur(240px);
    opacity: 0;
    transform: translateY(1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

@keyframes slideOutBlurredTop {
  0% {
    transform-origin: 50% 0;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 50% 0;
    filter: blur(240px);
    opacity: 0;
    transform: translateY(-1000px)scaleY(2)scaleX(.2);
  }
}

@keyframes slideOutBlurredBottom {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 50% 100%;
    filter: blur(240px);
    opacity: 0;
    transform: translateY(1000px)scaleY(2)scaleX(.2);
  }
}

@keyframes slideOutBlurredLeft {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 100%;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(-1000px)scaleX(2)scaleY(.2);
  }
}

@keyframes slideOutBlurredRight {
  0% {
    transform-origin: 50%;
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }

  100% {
    transform-origin: 0%;
    filter: blur(40px);
    opacity: 0;
    transform: translateX(1000px)scaleX(2)scaleY(.2);
  }
}

.Vue-Toastification__slideBlurred-enter-active.top-left, .Vue-Toastification__slideBlurred-enter-active.bottom-left {
  animation-name: slideInBlurredLeft;
}

.Vue-Toastification__slideBlurred-enter-active.top-right, .Vue-Toastification__slideBlurred-enter-active.bottom-right {
  animation-name: slideInBlurredRight;
}

.Vue-Toastification__slideBlurred-enter-active.top-center {
  animation-name: slideInBlurredTop;
}

.Vue-Toastification__slideBlurred-enter-active.bottom-center {
  animation-name: slideInBlurredBottom;
}

.Vue-Toastification__slideBlurred-leave-active:not(.disable-transition).top-left, .Vue-Toastification__slideBlurred-leave-active:not(.disable-transition).bottom-left {
  animation-name: slideOutBlurredLeft;
}

.Vue-Toastification__slideBlurred-leave-active:not(.disable-transition).top-right, .Vue-Toastification__slideBlurred-leave-active:not(.disable-transition).bottom-right {
  animation-name: slideOutBlurredRight;
}

.Vue-Toastification__slideBlurred-leave-active:not(.disable-transition).top-center {
  animation-name: slideOutBlurredTop;
}

.Vue-Toastification__slideBlurred-leave-active:not(.disable-transition).bottom-center {
  animation-name: slideOutBlurredBottom;
}

.Vue-Toastification__slideBlurred-leave-active, .Vue-Toastification__slideBlurred-enter-active {
  animation-duration: .75s;
  animation-fill-mode: both;
}

.Vue-Toastification__slideBlurred-move {
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
}

/*# sourceMappingURL=index.b545c644.css.map */
