
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

*{
  font-family: Lato;
}

:root {

  /* Colors: */
  --light-green: #70BB70;
  --light-gray: #E8E8E8;
  --white: #FFFFFF;
  --brown: #A59750;
  --dark-gray: #353535;
  --yellow: #F2CD32;
  --dark-green: #157116;
  --black: #000000;
  
  /* Font/text values */
  --unnamed-font-family-lato: Lato;
  --unnamed-font-family-fontawesome: FontAwesome;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-text-align: justify;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-32: 32px;
  --unnamed-font-size-40: 40px;
  --unnamed-font-size-50: 50px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-19: 25px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-39: 39px;
  --unnamed-line-spacing-48: 48px;
  --unnamed-line-spacing-60-000003814697266: 60.000003814697266px;
  }
  
  /* Character Styles */
  .textos {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: var(--unnamed-text-align);
  color: var(--dark-gray)
  }
  .fontawesome {
  font-family: var(--unnamed-font-family-fontawesome);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-50);
  line-height: var(--unnamed-line-spacing-60-000003814697266);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--black);
  }
  .h4 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  }
  .h3 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--amarillo-cc-secundario);
  }
  .h2 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-32);
  line-height: var(--unnamed-line-spacing-39);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-green);
  }
  .h1 {
  font-family: var(--unnamed-font-family-lato);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-40);
  line-height: var(--unnamed-line-spacing-48);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--negro-secundario);
  }

body {
  background-color: white;
}

.navbar-text a {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 16px/var(--unnamed-line-spacing-26) var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.sectionTitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-48) var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: left;
  font: normal normal bold 40px/48px Lato;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.followUpTitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-48) var(--unnamed-font-family-lato);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: left;
  font:  normal 40px/48px Lato;
  letter-spacing: 0px;
  color: #F2CD32;;
}


.sectionTitleMark{
  color: var(--yellow);
}

.iconColors{
  opacity: 0.25;
}

.newsletterText{
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 33px Lato;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.bannerText{
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 33px Lato;
  letter-spacing: 0px;
  color: var(--dark-green);
  opacity: 1;
}

.white{
  color: var(--white)
}

.dgray{
  color: var(--dark-gray)
}

.dgreen{
  color: var(--dark-green)
}

.d-greenbg{
  background-color: var(--dark-green)
}

.nft-video{
  border-radius: 5px;
}

.imgSize{
  height: 40px;
  width: 40px;
}
.web3modal-modal-lightbox {
  z-index: 5!important;
}
@media (max-width: 1300px) { 
  .newsletterText {
    font: bold 26px Lato;
  }
}
@media (max-width: 1100px) { 
  .newsletterText {
    font: bold 19px Lato;
  }
}
@media (max-width: 700px) {
  .sectionTitleMark{
    display: none!important;
  }
} 
@media (max-width: 400px) { 
  .newsletterText {
    text-align: center;
  }
  .container-input-mail{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
